<template>
  <div>
    <TaskDashboard />
    <v-card>
      <v-card-title>{{ $t('home.eventHistory') }}</v-card-title>
      <v-data-table dense :items="timeline" :headers="headers" sort-by="when" :sort-desc="true" mobile-breakpoint="375">
        <template v-slot:[`item.when`]="{ item }">
          {{ format(item.when) }}
        </template>
      </v-data-table>
    </v-card>
    <ul>
      <li>Status and Performance for Tasks</li>
      <li>Status and Performance for Grazing</li>
      <li>Status and Performance for Crops</li>
      <li>Status and Performance for Irrigation</li>
      <li>Status and Performance for Soil Development (Strips)</li>
      <li>Status and Performance for Livestock</li>
      <li>Status and Performance for Shop</li>
      <li>Status and Performance for Economic Situation</li>
    </ul>
  </div>
</template>

<script>
import i18n from '@/i18n'
import { formatDateTime } from '@/helpers'
import TaskDashboard from './tasks/TaskDashboard.vue'

export default {
  name: 'HomeDashboard',

  components: { TaskDashboard },

  data: () => ({
    timeline: [],
    headers: [
      { text: i18n.t('terms.tableHeaders.when'), align: 'start', value: 'when', sortable: true },
      { text: i18n.t('terms.tableHeaders.subject'), align: 'start', value: 'subject' },
      { text: i18n.t('terms.tableHeaders.message'), align: 'start', value: 'message', sortable: false }
      // { text: i18n.t('terms.tableHeaders.actions'), value: "actions", sortable: false },
    ]
  }),
  beforeMount: function () {
    return fetch('/api/timeline', {
      headers: {
        Authorization: 'Bearer ' + `${this.$keycloak.token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.timeline = data
      })
  },
  methods: {
    format (value) {
      return formatDateTime(value)
    }
  }
}
</script>
