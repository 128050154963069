<template>
  <v-card>
    <v-card-title>Tasks</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <div class="task-statistics">
            <div>Open tasks in total: {{ dashboard.openTasks.count }}, oldest: {{ dashboard.openTasks.oldestAge }}
              days</div>
            <div>Urgent tasks in total: {{ dashboard.urgentTasks.count }}, oldest: {{ dashboard.urgentTasks.count }}
              days</div>
            <div>Blocked tasks in total: {{ dashboard.blockedTasks.count }}, oldest: {{ dashboard.blockedTasks.count }}
              days</div>
          </div>
        </v-col>
        <v-col>
          <div class="task-statistics">
            <dl class="assignee">
              <div v-for="assignee in dashboard.assignees" :key="assignee">
                <dt>{{ assignee.assigneeName }}</dt>
                <dd>
                  <div v-if="showAssignee(assignee)">
                    <div>{{ assignee.openTasks.count }} open tasks. Oldest: {{ assignee.openTasks.oldestAge }} days</div>
                    <div>{{ assignee.urgentTasks.count }} urgent tasks. Oldest: {{ assignee.urgentTasks.oldestAge }} days
                    </div>
                    <div>{{ assignee.blockedTasks.count }} blocked tasks. Oldest: {{ assignee.blockedTasks.oldestAge }}
                      days
                    </div>
                  </div>
                  <div v-else>
                    There are no assigned tasks older than 5 days.
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style>
DL.assignee DT {
  font-weight: bold;
}
</style>

<script>
export default {
  name: 'TaskDashboard',

  data: () => ({
    dashboard: {
      openTasks: 0,
      urgentTasks: 0,
      blockedTasks: 0
    }
  }),

  beforeMount () {
    this.fetchDashboard()
  },

  methods: {
    showAssignee (assignee) {
      const threshold = 5

      return assignee.openTasks.oldestAge > threshold || assignee.urgentTasks.oldestAge > threshold || assignee.blockedTasks.oldestAge > threshold
    },

    fetchDashboard () {
      fetch('/api/tasks/dashboard', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.dashboard = data
        })
    }
  }
}
</script>
